.app-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  font-size: 13px;
  color: #ccc; }

.app-main {
  padding-bottom: 80px !important;
  position: relative;
  min-height: 100vh; }
