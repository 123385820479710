body .modal.fade .modal-dialog {
  transition: all 0.3s ease-in-out;
  transform: translate(0, 80px);
  opacity: 0; }

body .modal.show .modal-dialog {
  transform: translate(0, 0);
  opacity: 1; }

body .modal-body {
  padding: 24px 16px; }

body .modal-content {
  border-radius: 0; }

body .modal-backdrop.show {
  opacity: 0.65; }

body .modal-dialog {
  max-width: 950px !important; }

body .modal-content {
  border-radius: 0; }

body .modal-close {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  padding: 6px; }
  body .modal-close:hover {
    opacity: 0.7; }
